import axios from 'axios';
const TOKEN = 'Bearer fbbdc73d229626dc12dcabf032b3201d';

export default {

    async getClienteCommesse(id: string) {
        const data = new FormData();
        data.append("where[projects_customer_id]", id.toString());

        const response = await axios.post('/search/projects', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    async getCommessaDetail(id: string) {
        const data = new FormData();
        data.append("where[projects_id]", id.toString());

        const response = await axios.post('/search/projects', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data[0];
    },

    async getCommessaLibretto(id: string) {
        const data = new FormData();
        data.append("where[libretto_impianto_impianto]", id.toString());

        const response = await axios.post('/search/libretto_impianto', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response.data.data);
        return response.data.data;
    },

    async getMaterialeCommessa(id: string) {
        const data = new FormData();
        data.append("where[projects_billing_items_project]", id.toString());

        const response = await axios.post('/search/projects_billing_items', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response.data);
        return response.data.data;
    }
}