import axios from 'axios';
const TOKEN = 'Bearer fbbdc73d229626dc12dcabf032b3201d';

export default {

    async getTecnici() {
        const data = new FormData();
        //data.append("limit", "300");
        data.append("offset", "0");
        //data.append("orderby", "customers_company");
        //data.append("orderdir", "asc");

        const response = await axios.post('/search/users', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    }

}