import axios from 'axios';
const TOKEN = 'Bearer fbbdc73d229626dc12dcabf032b3201d';

export default {

    async getTickets(id: string) {
        const data = new FormData();
        //data.append("where[]", "tickets_data_assegnazione IS NOT NULL");
        data.append("where[]", "tickets_status <> 5");
        data.append("orderby", "tickets_assign_date");
        data.append("orderdir", "desc");
        data.append("where[]", `tickets_id IN (SELECT tickets_id FROM tickets_tecnici WHERE users_id = ${id.toString()})`);
        data.append("depth", "2");

        const response = await axios.post('/search/tickets', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    async getTicketDetail(id: string, userID: string) {
        const data = new FormData();
        data.append("where[]", `tickets_id IN (SELECT tickets_id FROM tickets_tecnici WHERE users_id = ${userID.toString()})`);
        data.append("where[tickets_id]", id.toString());
        data.append("depth", "2");

        const response = await axios.post('/search/tickets', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data[0];
    },

    /**
     * 
     * @param projectID commessa ID
     * @param userID user ID
     * @returns ticket assigned to me for a specified commessa
     */
    async getTicketCommessa(projectID: string, userID: string) {
        const data = new FormData();
        data.append("where[]", "tickets_status <> 5");
        data.append("where[]", `tickets_id IN (SELECT tickets_id FROM tickets_tecnici WHERE users_id = ${userID.toString()})`);
        data.append("where[tickets_project_id]", projectID.toString());

        const response = await axios.post('/search/tickets', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response.data);
        return response.data.data;
    },

    /**
     * 
     * @param intervento array with intervento data
     * @returns intervento just created
     * 
     * Aggiorna un intervento con la preferenza dell'invio mail al cliente, effettuato dal dettaglio intervento
     */
    async changeTicketStatus(idTicket) {
        const data = new FormData();
        data.append("tickets_status", "5");
        const response = await axios.post(`edit/tickets/${idTicket}`, data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

}